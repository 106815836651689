import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Location from 'components/layout/Posts/Location'
import Tiles from 'components/layout/Posts/PostsTiles2'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Wiadomości',
      link: '/wiadomosci/',
    },
    {
      label: 'Aktualności',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Wiadomości',
      link: '/wiadomosci/',
    },
    {
      label: 'Aktualności',
    },
  ],
}

const AktualnosciPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.aktualnosci
  const PAGE_SEO = data?.wpPage?.seo

  const GALLERY =
    React.useMemo(
      () =>
        data?.allWpAcrtile?.nodes?.map((article) => ({
          img: article?.artykul?.articleArticleImg?.localFile?.childImageSharp
            ?.gatsbyImageData,
          alt: article?.artykul?.articleArticleImg?.altText,
          link: `/wiadomosci/aktualnosci/${article.slug}/`,
          badge1Text: '',
          badge2Text: article?.title,
        })),
      []
    ) ?? []

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={PAGE?.heroImg?.localFile?.childImageSharp?.gatsbyImageData}
        imgDesktop={PAGE?.heroImg?.localFile?.childImageSharp?.gatsbyImageData}
        headingMobile={data?.wpPage?.title}
        headingDesktop={data?.wpPage?.title}
        breadcrumbs={breadcrumbs}
      />
      <Location label="Aktualności" />
      <Tiles heading="Aktualności" gallery={GALLERY} />
    </Layout>
  )
}

export default AktualnosciPage

export const query = graphql`
  query AktualnosciPage {
    wpPage(slug: { regex: "/aktualnosci/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      title
      aktualnosci {
        heroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    allWpAcrtile(
      filter: { artykul: { articleArticleTyp: { eq: "news" } } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        title
        slug
        artykul {
          articleArticleTyp
          articleArticleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
